<template>
    <v-container>
        <v-alert :type="alertType" v-model="alertDisplay" dismissible>{{alertMessage}}</v-alert>

        <v-row>
            <v-col sm="3">
                <v-text-field label="Search..." v-model="search" single-line hide-details></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col sm="2" class="d-flex align-center">
                <input type="checkbox" id="useExpirationFilterCheckbox" v-model="useExpirationFilter" @change="fetchSalesOppData">
                <label for="checkbox" style="margin-left: 10px">6 months</label>
            </v-col>
            <v-col sm="1" class="d-flex align-center">
                <input type="checkbox" id="useVolumeFilterCheckbox" v-model="useVolumeFilter" @change="fetchSalesOppData">
                <label for="checkbox" style="margin-left: 10px">10k</label>
            </v-col>
            <v-col sm="3" class="d-flex align-right pa-6">
                <v-select required class="small-select"
                          v-model="agentId"
                          :items="agents"
                          item-text="agentName"
                          item-value="agentId"
                          label="Select an Agent" dense
                          @change="fetchSalesOppData">
                </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col sm="1" class="d-flex align-right">
                <v-btn @click="helpDialog = true">
                    <span>Help</span>
            </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-data-table class="elevation-1"
                              :headers="headers"
                              :items="items"
                              :item-class="itemRowClass"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :items-per-page="15"
                              :footer-props="{'items-per-page-options':[15, 25, 50, 100, -1]}"
                              :search="search"
                              @click:row="handleRowClick"
                              :loading="loading"
                              loading-text="Loading... Please wait">
                    <template v-slot:items="props">
                        <td class="text-xs-right">{{ props.item.oppId }}</td>
                        <td class="text-xs-right">{{ props.item.dateStart }}</td>
                        <td class="text-xs-right font-weight-bold">{{ props.item.name }}</td>
                        <td class="text-xs-left">{{ props.item.objective }}</td>
                        <td class="text-xs-right">{{ props.item.oppColor }}</td>

                        <td class="text-xs-right">{{ props.item.status }}</td>
                        <td class="text-xs-right">{{ props.item.oppStage }}</td>
                        <td class="text-xs-right">{{ props.item.quoteNum }}</td>
                        <!--<td class="text-xs-right" :style="{color: (props.item.revenueCurrencyId == 'CAD' ? 'red' : 'green' ) }">{{ props.item.revenueCurrencyId }}</td>-->
                        <td class="text-xs-right" style="color:red">{{ props.item.revenueCurrencyId }}</td>
                        <td class="text-xs-right">{{ props.item.estimateTotal }}</td>
                        <td class="text-xs-right">{{ props.item.expectedDate }}</td>
                        <td class="text-xs-right">{{ props.item.confRate }}</td>
                        <td class="text-xs-right">{{ props.item.oppColor }}</td>
                    </template>
                    <template #[`item.name`]="{ item }">
                        <b>{{ item.name }}</b>
                    </template>
                    <template #[`item.revenueCurrencyId`]="{ item }">
                        <v-chip :color="getCurrencyColor(item)">
                            {{ item.revenueCurrencyId }}
                        </v-chip>
                    </template>
                    <!--<template #[`item.confRate`]="{ item }">
                    <v-chip :color="getConfRateColor(item)">
                        {{ convertConfRate(item.confRate) }}
                    </v-chip>
                </template>-->
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                              v-slot:[`item.${header.value}`]="{ header, value }">
                        {{ header.formatter(value) }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row xs12 class="text-xs-right font-weight-bold" justify="center" style="padding-bottom: 50px;">
            <v-card class="d-flex justify-center xs-6" style="margin-right:15px;">Sales Estimate Total (CAD): $ {{ totals.cad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</v-card>
            <v-card class="d-flex justify-center xs-6" style="margin-left:15px;">Sales Estimate Total (USD): $ {{ totals.usd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</v-card>
        </v-row>

        <v-dialog v-model="salesOppDialog" max-width="1200" persistent @keydown.esc="salesOppDialog = false">
            <v-card v-if="activeSalesOpp">
                <v-form ref="form">
                    <v-card-title class="headline grey lighten-2 py-2" primary-title>
                        <v-flex xs12 v-if="!salesOppLostDialog">
                            Edit Opportunity {{ activeSalesOpp.objective }} {{ activeSalesOpp.name }} {{ activeSalesOpp.quoteNum }}
                        </v-flex>
                        <v-flex xs12 v-if="salesOppLostDialog">
                            Mark Opportunity as 'Lost' {{ activeSalesOpp.objective }} {{ activeSalesOpp.name }}
                        </v-flex>
                        <v-flex xs6 class="text-xs-right">
                            <v-btn @click="salesOppDialog = false" icon>
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-alert :type="alertTypeSalesOpp" v-model="alertDisplaySalesOpp" dismissible>{{alertMessageSalesOpp}}</v-alert>
                    <v-divider></v-divider>
                    <v-layout v-if="!salesOppLostDialog" class="px-3 mb-2">
                        <v-flex xs12>
                            <v-flex xs6>
                                <v-menu v-model="showPicker"
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="activeSalesOpp.expectedDate"
                                                      label="Expected Sign Off Date"
                                                      prepend-icon="mdi-calendar"
                                                      persistent-hint
                                                      readonly
                                                      v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="activeSalesOpp.expectedDate"
                                                   no-title
                                                   scrollable
                                                   @input="showPicker = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs6>
                                <v-select required class="small-select"
                                          v-model="activeSalesOpp.confRateCode"
                                          :items="confRates"
                                          item-text="value"
                                          item-value="key"
                                          label="Confidence Rating %" dense>
                                </v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea label="Add new note" rows="3" v-model="activeSalesOpp.newNote" multi-line></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Historical Notes
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-flex>
                                                <v-data-table class="elevation-1"
                                                              :headers="notesHeaders"
                                                              :items="notes"
                                                              :sort-by="['dateCol']"
                                                              hide-default-header
                                                              hide-default-footer
                                                              disable-pagination
                                                              :loading="loading"
                                                              loading-text="Loading... Please wait">
                                                    <template v-slot:items="props">
                                                        <td class="text-xs-right">{{ props.item.dateCol }}</td>
                                                        <td class="text-xs-right">{{ props.item.textCol }}</td>
                                                    </template>
                                                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                                                              v-slot:[`item.${header.value}`]="{ header, value }">
                                                        {{ header.formatter(value) }}
                                                    </template>
                                                </v-data-table>
                                            </v-flex>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-flex>
                        </v-flex>
                    </v-layout>

                    <v-layout v-if="salesOppLostDialog" class="px-3 mb-2">

                        <v-flex xs12>
                            <v-flex xs6>
                                <v-menu v-model="showPicker"
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="required"
                                                      v-model="activeSalesOpp.lostDate"
                                                      label="Date Lost"
                                                      prepend-icon="mdi-calendar-range"
                                                      persistent-hint
                                                      readonly
                                                      v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="activeSalesOpp.lostDate"
                                                   no-title
                                                   scrollable
                                                   @input="showPicker = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs6> </v-flex>
                            <v-flex xs6>
                                <v-select class="small-select required"
                                          v-model="activeSalesOpp.lostReason"
                                          :items="opportunityLostReasons"
                                          item-text="stepName"
                                          item-value="stepId"
                                          label="Select Reason" dense>
                                </v-select>
                            </v-flex>
                            <v-flex xs6> </v-flex>
                            <v-flex xs12>
                                <v-textarea label="Comment" rows="3" v-model="activeSalesOpp.lostNote" multi-line
                                            counter="508"
                                            maxlength="508">></v-textarea>
                            </v-flex>
                        </v-flex>
                    </v-layout>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn v-if="activeSalesOpp.oppColor != 'GR' && activeSalesOpp.oppColor != 'RD'"
                               color="success" depressed @click="oppWon" :disabled="oppWonButtonDisabled || salesOppLostDialog">
                            Opp Won
                        </v-btn>
                        <v-btn v-if="!salesOppLostDialog && (activeSalesOpp.oppColor == 'WH' || activeSalesOpp.oppColor == 'YL')"
                               class="danger" depressed @click="oppLost" :disabled="oppLostButtonDisabled">
                            Opp Lost
                        </v-btn>
                        <v-btn v-if="salesOppLostDialog" color="primary" depressed @click="resetOppLost" :disabled="resetOppLostButtonDisabled">
                            Reset
                        </v-btn>
                        <v-btn color="success" depressed @click="saveSalesOpp" :disabled="saveSalesOppButtonDisabled">
                            Save
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" depressed @click="closeSalesOppDialog" :disabled="closeSalesOppButtonDisabled">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="helpDialog" max-width="800" persistent @keydown.esc="helpDialog = false">
            <v-card>
                <v-form ref="formHelpDialog">
                    <v-card-title class="headline grey lighten-2 py-2" primary-title>
                        <v-flex xs6>
                            Opportunities Info
                        </v-flex>
                        <v-flex xs6 class="text-xs-right">
                            <v-btn @click="helpDialog = false" icon>
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-layout class="px-3 mb-2">
                        <v-flex xs12>
                            <h3>
                                The following Opportunities are shown:
                            </h3>
                            <v-spacer></v-spacer>
                            <ul>
                                <li>
                                    In-Progress, Stages 1, 3
                                    <ul>
                                        <li>Stage 1 - Needs Assessment</li>
                                        <li>Stage 3 - Estimating - Issued For Approval</li>
                                    </ul>
                                </li>
                                <li>
                                    Won (In Design), Stages 4, 5
                                    <ul>
                                        <li>Stage 4 - Being Designed</li>
                                        <li>Stage 5 - Design, Issued for Approval</li>
                                    </ul>
                                </li>
                            </ul>

                            <v-spacer></v-spacer>
                            <v-flex xs12 style="margin-top:15px;">
                                <div class="color-box light-green-cell"></div>
                                <span>Won (In Design)</span>
                            </v-flex>
                            <v-flex xs12>
                                <div class="color-box dandelion-cell"></div>
                                <span>In Progress (updated in the last 7 days)</span>
                            </v-flex>
                            <v-flex xs12>
                                <div class="color-box"></div>
                                <span>In Progress</span>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex xs12 style="margin-top:15px;">
                                <h4>Exchange Rate: 1 USD = 1.31 CAD</h4>
                            </v-flex>
                        </v-flex>
                    </v-layout>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" depressed @click="helpDialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
    name: "home",
    components: {},
    data () {
        return {
        alertType: null,
        alertMessage: null,
        alertDisplay: false,
        alertTypeSalesOpp: null,
        alertMessageSalesOpp: null,
        alertDisplaySalesOpp: false,
        sortBy: "dateStart",
        sortDesc: false,
        search: "",
        loading: true,
        showPicker: false,
        salesOppDialog: false,
        salesOppLostDialog: false,
        helpDialog: false,
        activeSalesOpp: null,
        confRates: [{ value: "100%", key: 1 }, { value: "75%", key: 2 }, { value: "50%", key: 57998 }, { value: "25%", key: 57996 }, { value: "Not Assigned", key: 5 }],
        items: [],
        agents: [],
        notes: [],
        opportunityLostReasons: [],
        agentId: "",
        useVolumeFilter: true,
        useExpirationFilter: true,
        saveSalesOppButtonDisabled: false,
        oppWonButtonDisabled: false,
        oppLostButtonDisabled: false,
        resetOppLostButtonDisabled: false,
        closeSalesOppButtonDisabled: false,
        totalItems: 0,
        currentPage: 1,
        options: {},
        dateFormat: "YYYY-MM-DD",
        dateAndTimeFormat: "YYYY-MM-DD",
        rules: {
            required: value => !!value || 'Required',
            requiredSelect: [(v) => v.length > 0 || 'Required']
        },
        notesHeaders:
            [
                {
                    text: "Date",
                    value: "dateCol",
                    filterable: false,
                    sortable: false,
                    align: 'left',
                    width: '30%',
                    formatter: (x) => (x ? moment(x).format(this.dateAndTimeFormat) : null)
                },
                {
                    text: "Text",
                    align: "left",
                    value: "textCol",
                    filterable: true,
                    sortable: true,
                    width: '70%'
                }
            ],
        headers:
            [
                {
                    text: "OppId",
                    value: "oppId",
                    filterable: false,
                    sortable: false,
                    align: ' d-none',
                    width: '0%'
                },            {
                    text: "Start Date",
                    align: "left",
                    value: "dateStart",
                    filterable: true,
                    sortable: true,
                    width: '10%',
                    formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
                },
                {
                    text: "Customer",
                    value: "name",
                    align: 'left',
                    filterable: true,
                    sortable: true,
                    width: '15%',
                    class: 'font-weight-bold'
                },
                {
                    text: "Opportunity",
                    value: "objective",
                    filterable: true,
                    sortable: true,
                    align: 'left',
                    width: '15%'
                },
                {
                    text: "oppColor",
                    value: "oppColor",
                    filterable: true,
                    sortable: true,
                    align: ' d-none',
                    width: '0%'
                },
                {
                    text: "Status",
                    value: "state",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '10%'
                },
                {
                    text: "StatusId",
                    value: "status",
                    filterable: false,
                    sortable: false,
                    align: ' d-none',
                    width: '0%'
                },
                {
                    text: "Stage",
                    value: "oppStage",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '5%',
                    formatter: (x) => (x.substring(0, 1))
                },
                {
                    text: "Quote #",
                    value: "quoteNum",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '10%'
                },
                {
                    text: "Currency",
                    value: "revenueCurrencyId",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '5%'
                },
                {
                    text: "Total Value",
                    value: "estimateTotal",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '10%',
                    formatter: (x) => (x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                },
                {
                    text: "Exp. Sign Off",
                    value: "expectedDate",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '10%',
                    formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
                },
                {
                    text: "%",
                    value: "confRate",
                    filterable: true,
                    sortable: true,
                    align: 'end',
                    width: '5%',
                    formatter: (x) => ((!x || x == 'Not Assigned') ? '-' : x.replace('%', ''))
                },
                {
                    text: "OppColor",
                    value: "oppColor",
                    filterable: false,
                    sortable: false,
                    align: ' d-none',
                    width: '0%'
                }
            ]
        }
    },
    watch: {
    },
    computed: {
        totals() {
            const totals = this.items.reduce((a, b) => {
                a.cad += b.revenueCurrencyId == 'CAD' ? b.estimateTotal : 0
                a.usd += b.revenueCurrencyId == 'USD' ? b.estimateTotal : 0
                return a
            },
            { cad: 0, usd: 0 })
            totals.cad = totals.cad.toFixed(0)
            totals.usd = totals.usd.toFixed(0)
            return totals
        }
    },

    created() {
        this.getAgentsFromApi()
            .then(data => {
                this.agents = data.items;
                if(this.agents && this.agents.length > 0) {
                    this.agentId = this.agents[0].agentId;
                    this.fetchSalesOppData();
                }
            });

        this.getReasonsLostListFromApi()
            .then(data => {
                this.opportunityLostReasons = data.items;
            });
    },

    methods: {
        showAlert(type, message) {
            this.alertType = type;
            this.alertMessage = message;
            this.alertDisplay = true;
        },
        showSalesOppAlert(type, message) {
            this.alertTypeUser = type;
            this.alertMessageUser = message;
            this.alertDisplayUser = true;
        },
        setButtonsDisabledValue(value) {
            this.saveSalesOppButtonDisabled = value;
            this.oppWonButtonDisabled = value;
            this.oppLostButtonDisabled = value;
            this.resetOppLostButtonDisabled = value;
            this.closeSalesOppButtonDisabled = value;
        },
        getCurrencyColor(item) {
            return item.revenueCurrencyId == "USD" ? "green" : "red";
        },
        convertConfRate(item) {
            //return ((!item || item == 'Not Assigned') ? '-' : item.replace('%', ''));
            return (!item || item == 'Not Assigned') ? '-' : item;
        },
        getConfRateColor(item)
        {
            var color = "transparent";
            if (item) {
                const confRate = item.confRate;
                switch (confRate) {
                    case "25%":
                        color = "yellow";
                        break;
                    case "50%":
                        color = "yellow";
                        break;
                    case "75%":
                        color = "#90ee90";
                        break;
                    case "100%":
                        color = "green";
                        break;
                    default:
                        color = "transparent";
                        break;
                }
            }
            return color;
        },
        handleRowClick(row) {
            console.log(row.oppId);
            this.activeSalesOpp = {
                oppId: row.oppId,
                dateModified: row.dateModified,
                status: row.status,
                updtDaysAgo: row.updtDaysAgo,
                dateStart: row.dateStart,
                closeDate: row.closeDate,
                name: row.name,
                objective: row.objective,
                confRate: row.confRate,
                confRateCode: row.confRateCode,
                forecastRevenue: row.forecastRevenue,
                revenueCurrencyId: row.revenueCurrencyId,
                quoteNum: row.quoteNum,
                expectedDate: row.expectedDate == null ? null : moment(row.expectedDate).format(this.dateFormat),
                expectedDateVal: row.expectedDateVal,
                ownerId: row.ownerId,
                agentName: row.agentName,
                agentId: row.agentId,
                oppColor: row.oppColor,
                oppStage: row.oppStage,
                state: row.state,
                newNote: null,
                lostReason: null,
                lostNote: null,
                lostDate: null,
                originalConfRateCode: row.confRateCode,
                originalExpectedDate: row.expectedDate == null ? null : moment(row.expectedDate).format(this.dateFormat),
                originalStatus: row.status
            };

            this.alertDisplay = false;
            this.alertDisplaySalesOpp = false;
            this.salesOppDialog = true;
            this.salesOppLostDialog = false;
            this.setButtonsDisabledValue(false);
            this.fetchOppNotes(row.oppId);
        },
        closeSalesOppDialog() {
            this.activeSalesOpp = null;
            this.salesOppDialog = false;
            this.salesOppLostDialog = false;
            this.showPicker = false;
            this.alertDisplay = false;
            this.alertDisplaySalesOpp = false;
        },
        saveSalesOpp() {
            this.alertDisplaySalesOpp = false;
            if (this.salesOppLostDialog) {
                if (!this.activeSalesOpp.lostReason || !this.activeSalesOpp.lostDate) {
                    this.alertTypeSalesOpp = "error";
                    this.alertMessageSalesOpp = "Required fields are not filled!";
                    this.alertDisplaySalesOpp = true;
                    return false;
                }
            }
            this.updateSalesOpp();
        },
        oppWon() {
            var confirmResult = confirm("Please confirm");

            if (confirmResult) {
                this.activeSalesOpp.stage = 4;
                this.updateSalesOpp();
            }
        },
        oppLost() {
            this.activeSalesOpp.lostReason = null;
            this.activeSalesOpp.lostNote = null;
            this.activeSalesOpp.lostDate = null;
            this.salesOppLostDialog = true;
            this.alertDisplaySalesOpp = false;
        },
        resetOppLost() {
            this.salesOppLostDialog = false;
            this.alertDisplaySalesOpp = false;
            this.activeSalesOpp.lostReason = null;
            this.activeSalesOpp.lostNote = null;
            this.activeSalesOpp.lostDate = null;
        },
        itemRowClass: function (item) {
            var className = "";
            if (item.oppColor == "YL") {
                className = "dandelion-cell"
            }
            else if (item.oppColor == "RD") {
                className = "pink-cell"
            }
            else if (item.oppColor == "GR") {
                className = "light-green-cell"
            }
            return className;
        },
        formatStatus(value) {
            return (value == 'WH' || value == 'YL') ? 'In progress' : value == 'RD' ? 'Lost' : value == 'GR' ? 'Won(In Design)' : '';
        },
        formatDate(value) {
              return moment(value).format("YYYY-M-DD");
        },
        fetchSalesOppData() {
            this.getDataFromApi()
                .then(data => {
                    this.items = data.items;
            })
        },
        fetchOppNotes(oppId) {
            this.getOppNotesFromApi(oppId)
                .then(data => {
                    this.notes = data.items;
                })
        },
        updateSalesOpp() {
            var lostReasonText;
            if (this.activeSalesOpp.lostReason) {
                var lostReason = this.opportunityLostReasons.find(obj => {
                    return obj.stepId == this.activeSalesOpp.lostReason;
                });
                lostReasonText = lostReason.stepName;
            }
            this.setButtonsDisabledValue(true);

            axios.post(`SalesOpp/UpdateOpp`,
                {
                    OppId: this.activeSalesOpp.oppId,
                    Note: this.activeSalesOpp.newNote,
                    EstimateDate: this.activeSalesOpp.expectedDate,
                    OriginalEstimateDate: this.activeSalesOpp.originalExpectedDate,
                    ConfRateCode: this.activeSalesOpp.confRateCode,
                    OriginalConfRateCode: this.activeSalesOpp.originalConfRateCode,
                    Stage: this.activeSalesOpp.stage,
                    Status: this.activeSalesOpp.status,
                    OriginalStatus: this.activeSalesOpp.originalStatus,
                    LostNote: this.activeSalesOpp.lostNote,
                    LostReasonId: this.activeSalesOpp.lostReason,
                    LostReasonText: lostReasonText,
                    LostDate: this.activeSalesOpp.lostDate
                })
                .then((result) => {
                    console.error("SalesOpp/UpdateOpp result: " + JSON.stringify(result));
                    if (result.data) {
                        this.showAlert("success", "Sales opportunity updated");
                        this.setButtonsDisabledValue(false);
                        this.activeSalesOpp = null;
                        this.salesOppDialog = false;
                        this.salesOppLostDialog = false;
                        this.showPicker = false;
                        this.fetchSalesOppData();
                    }
                    else {
                        this.showSalesOppAlert("error", "An error occured while updating the sales opportunity!");
                        this.setButtonsDisabledValue(false);
                    }

                }).catch((err) => {
                    console.log(err);
                    this.showSalesOppAlert("error", "An error occured while updating the sales opportunity!");
                    this.setButtonsDisabledValue(false);
                }).finally(() => {
                });
        },
        getOppNotesFromApi(oppId) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                axios.get(`SalesOpp/GetSalesOppNotes/${oppId}`)
                    .then((result) => {
                        let items = result.data;
                        resolve({
                            items
                        });
                    }).catch((err) => {
                        console.log(err);
                        this.showSalesOppAlert("error", "An error occured while getting the sales opportunity notes!");
                        reject(err);
                    }).finally(() => {
                        this.loading = false;
                });
            })
        },
        getAgentsFromApi() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                axios.get(`SalesOpp/GetAssignedAgents`)
                    .then((result) => {
                        let items = result.data;
                        resolve({
                            items
                        });
                    }).catch((err) => {
                        console.log(err);
                        this.showAlert("error", "An error occured while getting assigned agents!");
                        reject(err);
                    }).finally(() => {
                        this.loading = false;
                });
            })
        },

        getReasonsLostListFromApi() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                axios.get(`SalesOpp/GetReasonsLostList`)
                    .then((result) => {
                        let items = result.data;
                        resolve({
                            items
                        });
                    }).catch((err) => {
                        console.log(err);
                        this.showAlert("error", "An error occured while getting the list of reasons of loss!");
                        reject(err);
                    }).finally(() => {
                        this.loading = false;
                    });
            })
        },

        getDataFromApi () {
          this.loading = true;

            return new Promise((resolve, reject) => {
                if (!this.search) {
                    this.search = '';
                }

                const agentId = this.agentId;
                const useVolumeFilter = this.useVolumeFilter;
                const useExpirationFilter = this.useExpirationFilter;
          
                axios.get(`SalesOpp/GetSalesOppList/${agentId}/${useVolumeFilter}/${useExpirationFilter}`)
                .then((result) =>
                {
                    let items = result.data;

                    resolve({
                        items,
                    });
                }).catch((err) => {
                    console.log(err);
                    this.showAlert("error", "An error occured while getting list of sales opportunities!");
                    reject(err);
                }).finally(() => {
                this.loading = false;
            });
          })
        }
    }
};
</script>

<style>
    .dandelion-cell {
        background-color: #ffeaab !important;
    }

    .pink-cell {
        background-color: #ffc7ce !important;
    }

    .light-green-cell {
        background-color: #d1f2d7 !important;
    }

    .danger {
        background-color: #dc3545 !important;
    }

    .required label::after {
        content: "*";
    }

    .color-box {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        border: 1px solid black;
        display: inline-block;
    }
</style>